import axios from 'axios';

const API = 'cupones';

const ENDPOINTS = {
    getListaCupones(){
        return axios.get(`${API}/listado`)
    },
    getCuponesCarrito(params){
        return axios.get(`${API}/lista-carrito`, {params})
    },
    busquedaCuponesCarrito(params){
        return axios.get(`${API}/codigo`, {params})
    },

};

export default ENDPOINTS;