<template>
    <modal ref="modalCupon" titulo="Cupon" no-adicional no-cancelar>
        <div v-if="cupones.length > 0">
            <div v-for="(data, idx) in cupones" :key="idx" class="row mx-0 j-center mb-3">
                <div class="card-cupon d-flex position-relative cr-pointer" :class="cuponActivo == idx + 1 ? 'border-active' : ''" @click="verCupon( data )">
                    <div class="space-img pr-3 h-100 d-middle">
                        <img v-if="data.descuento_tipo == 1" src="/img/ilustraciones/descount.svg" width="50" height="50" />
                        <img v-else src="/img/ilustraciones/cash.svg" width="50" height="50" />
                    </div>
                    <div class="col px-2">
                        <div class="row mx-0 align-items-end text-purple pt-2">
                            <div v-if="data.descuento_tipo == 1" class="row mx-0 align-items-end text-purple pt-2">
                                <div class="col-auto px-0 f-600 f-30 text-cupon">
                                    {{ data.descuento_nombre }} %
                                </div>
                                <small class="pl-2">
                                    Descuento
                                </small>
                            </div>
                            <div v-else class="row mx-0 align-items-end text-purple pt-2">
                                <div class="col-auto px-0 f-600 f-30 text-cupon">
                                    {{ formatNumero(data.descuento_nombre) }}
                                </div>
                            </div>
                        </div>
                        <p class="mt-3">
                            {{ data.nombre }}
                        </p>
                        <p class="mt-1 f-14">
                            Expira: {{ formatearFecha(data.expira) }}
                        </p>
                    </div>
                    <!-- Circulo cupo -->
                    <div :class="`circled-coupon${ cuponActivo == idx + 1 ? '-active' : '' }`" />
                </div>
            </div>
        </div>
        <div v-else class="row mx-0 j-center mb-3">
            <img :src="funImagenGeneral(46)" />
            <span class="text-danger w-100 f-15 row mx-0 j-center">No tienes cupones disponibles</span>
        </div>
    </modal>
</template>

<script>
import Cupones from '~/services/cupones/cupones'
export default {
    data(){
        return {
            buscar: '',
            cuponActivo: null,
            cupones: [],
            detalle_cupon: null
        }
    },
    methods: {
        toggle(){
            this.listaCupones(),
            this.$refs.modalCupon.toggle();
        },
        verCupon(item){
            this.detalle_cupon = item
            this.$emit("detalleCupon", this.detalle_cupon)
            this.$refs.modalCupon.toggle();
        },     
        async listaCupones(){
            try {
                const { data } = await Cupones.getListaCupones();
                this.cupones = data.cupones;
            } catch (e){
                this.errorCatch(e)
            }          
        },
    }
}
</script>
<style lang="scss" scoped>
.button-search{
    height: 44px;
    border-radius: 20px;
}
.btn-buscar{
    background-color: var(--color-general) !important;
    position: absolute;
    right: 4px;
    height: 36px;
    top: 3px;
    border: 0px;
    border-radius: 20px;
    color: #FFFFFF;
    height: 34px;
    &:focus{
        outline: none !important;
    }
}
.card-cupon{
    width: 346px;
    height: 108px;
    border-radius: 12px;
    padding-left: 35px;
    box-shadow: 0px 2px 4px #00000014;
    z-index: 2;
    .circled-coupon{
        width: 44px;
        position: absolute;
        left: -22px;
        top: 31px;
        height: 44px;
        background-color: white;
        z-index: 1;
        border-radius: 50%;
        box-shadow: 1px 0px 4px #00000014;
        &::after{
            content: '';
            position: absolute;
            left: -24px;
            top: -1px;
            z-index: 2;
            height: 44px;
            width: 44px;
            background-color: #FFFFFF
        }
        &-active{
            @extend .circled-coupon;
            border: 1px solid var(--color-general);
        }
    }
    &.border-active{
        border: 1px solid var(--color-general);
    }
    .space-img{
        border-right: dashed 3px #F6F6F6;
    }
    .text-cupon{
        line-height: 29px;
    }
    .text-detail{
        color: #b1b9c1;
    }
    .text-fetch{
        color: #D3D7DB;
    }
}
</style>